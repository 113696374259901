import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import billaCard from "./assets/billa.jpg";
import multisport from "./assets/multisport.jpg";
import decathlon from "./assets/decathlon.jpg";
import ikea from "./assets/ikea.jpg";
const Barcode = require("react-barcode");

const cardsList = {
  billa: {
    name: "Billa",
    img: billaCard,
    code: "9981006487723",
    format: "CODE128"
  },
  multisport: {
    name: "Multisport",
    img: multisport,
    code: "003050440360",
    format: "CODE128"
  },
  ikea: {
    name: "Ikea",
    img: ikea,
    code: "1521922000439",
    format: "CODE128"
  },
  decathlon: {
    name: "Decathlon",
    img: decathlon,
    code: "2090482037463",
    format: "CODE128"
  }
};

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Route exact path="/" component={Home} />
        <Route path="/:card/" render={router => <About router={router} />} />
      </div>
    </Router>
  );
};

function Home() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h2 style={{ width: "100%", textAlign: "center" }}>My Cards</h2>

      <ul style={{ listStyle: "none", margin: "0", padding: "0 2px" }}>
        {Object.entries(cardsList).map(card => (
          <li
            style={{
              width: "100%",
              marginBottom: "10px",
              height: "200px",
              overflow: "hidden",
              borderRadius: "10px"
            }}
          >
            <Link to={`/${card[0]}`}>
              <img width="100%" src={`${card[1].img}`} alt={card[1].name} />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function About(props) {
  const cardParam = props.router.match.params.card;
  const card = cardsList[cardParam];

  if (card === null) {
    return (
      <div>
        <Link to="/">Back</Link>
        <div>No card was selected.</div>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "0 2px" }}>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex" }}>
          <Link
            to="/"
            style={{
              fontSize: "20px",
              color: "#000000",
              backgroundColor: "#ebebeb",
              textDecoration: "none",
              border: "solid 1px #ebebeb",
              borderRadius: "10px",
              borderTopLeftRadius: "1000px",
              borderBottomLeftRadius: "1000px",
              padding: "10px 20px",
              margin: "10px 0"
            }}
          >
            Cards
          </Link>
        </div>
        <div style={{ display: "flex", flex: "1" }}>
          <h2 style={{ textAlign: "center", flex: "1" }}>{card.name}</h2>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: "1",
          maxHeight: "200px",
          overflow: "hidden",
          justifyContent: "center"
        }}
      >
        <img
          src={card.img}
          alt={card.name}
          style={{ borderRadius: "10px", width: "100%" }}
        />
      </div>
      <div style={{ display: "flex", flex: "1", justifyContent: "center" }}>
        <Barcode
          value={card.code}
          width="3"
          textAlign="center"
          format={card.format}
        />
      </div>
    </div>
  );
}

export default App;

// Barcode options
// {
//     width: 2,
//     height: 100,
//     format: "CODE128",
//     displayValue: true,
//     fontOptions: "",
//     font: "monospace",
//     textAlign: "center",
//     textPosition: "bottom",
//     textMargin: 2,
//     fontSize: 20,
//     background: "#ffffff",
//     lineColor: "#000000",
//     margin: 10,
//     marginTop: undefined,
//     marginBottom: undefined,
//     marginLeft: undefined,
//     marginRight: undefined
// }
